*
{
    margin: 0;
    padding: 0;
}

html,
body
{   
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow: hidden;
    user-select: none;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    z-index: -1;
}

#main-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1.0);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

@keyframes fadeOut {
    0% {
      background-color: rgba(0, 0, 0, 1.0);
    }
    100% {
      background-color: rgba(0, 0, 0, 0.0);
    }
}
  
.fade-out {
    animation: fadeOut 3s ease 3s; 
    animation-fill-mode: forwards;
}

.startButton-fade-out {
    animation: fadeOut 2s ease 0.2s; 
    animation-fill-mode: forwards;
}

@keyframes scaleAnimation {
    0% {
        transform: translateX(-50%) scale(1);
    }
    50% {
        transform: translateX(-50%) scale(1.05);
    }
    100% {
        transform: translateX(-50%) scale(1);
    }
}

#interact-spot-text {
    position: absolute;
    bottom: 8%;
    left: 50%;
    transform: translateX(-50%);
    color: rgb(255, 255, 255);
    z-index: 2;
    text-align: center;
    font-size: 3vh;
    animation: scaleAnimation 5s infinite;
    white-space: nowrap;
    width: 100%;
    opacity: 0.8;
}
@media (max-width: 768px) {
    #interact-spot-text {
        top: 50%;
        bottom: auto;
    }
}

#loading-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#loading-text {
    color: white; 
    font-size: 32px;
    margin-top: 10px;
}

#spinner {
    width: 64px;
    height: 64px;
}

#portfolio-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(99, 97, 97, 0.7);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

#portfolio-iframe {
    width: 100%;
    height: 100%;
}

.portfolio-close-symbol {
    position: absolute;
    top: 3%;
    right: 4%;
    cursor: pointer;
    font-size: 30px;
    color: #fff;
    background-color: black;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    border: 3px solid #fff;
}

.portfolio-container {
    width: 90%;
    height: 90%;
}

@keyframes portfolioMaximize {
    from {
        opacity: 0.5;
        max-height: 0;
    }
    to {
        opacity: 1;
        max-height: 90%;
    }
}

.portfolio-maximize {
    animation: portfolioMaximize 0.6s ease-in-out forwards;
}

@keyframes clickTextFadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
.clickText-fade-in {
    animation: clickTextFadeIn 2s ease-in-out;
}

.settings-panel {
    width: auto;
    height: auto;
    min-width: 50px;
    min-height: 50px;
    position: absolute;
    top: 1px;
    right: 1px;
    
    display: flex;
    align-items: center;
    justify-content: end;
    /* flex-direction: column; */
    color: #e8e6e6;
    z-index: 1;
    cursor: pointer;
    
}
@media(hover: hover) and (pointer: fine) {
    .settingsImage:hover {
        opacity: 1;
    }  
}


.settingsImage {
    user-select: none;
    opacity: 0.8;
    transition: transform 0.5s ease;
}

.settingsImage.active {
    opacity: 1;
}

.imageRotated {
    transform: rotate(45deg);
}

.tooltip{
    position: relative;
    background-color: rgba(164, 160, 160, 0.5);     
    width: 100%; 
    height: auto;   
    border-radius: 5px;
    padding: 10px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    -webkit-transition: opacity 0.5s, visibility 0.5s, width 0.5s, height 1s;
    -moz-transition: opacity 0.5s, visibility 0.5s, width 0.5s, height 1s;
    -o-transition: opacity 0.5s, visibility 0.5s, width 0.5s, height 1s;
    transition: opacity 0.5s, visibility 0.5s, width 0.5s, height 1s;
}

.tooltip:before {
    position: absolute;
    content: '';
    border-width: 10px;
    border-style: solid;
    border-top: 5px solid transparent;
    border-left: 12px solid rgba(164, 160, 160, 0.5);
    border-right: 0px solid transparent;
    border-bottom: 5px solid transparent;
    top: 50%; 
    right: -12px;
    transform: translateY(-50%);
    transition: opacity 0.5s, visibility 0.5s;
}

.tooltip.hidden{
    opacity: 0;
    width: 1px;
    height: 1px;
    visibility: hidden;
    color: rgba(1, 1, 1, 0.0);
}

.tooltip:before.hidden{
    opacity: 0;
    visibility: hidden;
}

.settings-popup.hidden {
    display: none;
}

.settings-popup.shown {
    display: flex;
}

.settings-popup {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 3;
}

.settings-popup-window {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    background: #f0f9ff;
    width: 95vw;
    max-width: 500px;
    height: 320px;
    /* min-height: 350px; */
    /* border: 2px solid black; */
    border-radius: 3px;
    /* box-shadow: -4px 4px 0px black; */
}

.settings-popup-pattern1 {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #deebf4;
    clip-path: polygon(100% 0%, 100% 17.5%, 95% 9%, 35% 9%, 30% 17%, 0% 17%, 0% 0%);
    border-radius: 3px;
}

.settings-popup-pattern2 {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #e7f7fc;
    clip-path: polygon(100% 0%, 100% 16%, 95% 8%, 35% 8%, 30% 16%, 0% 16%, 0% 0%);
    border-radius: 3px;
}

.check-box-option-container {
    width: 60%;
    min-width: 250px;
    height: 16%;
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    margin-left: 20px;
}

.check-box-option-container.firstItem {
    margin-top: 30px;
}

.check-box-container {
    transform: scale(0.25);
}

.settings-popup-close-btn{
    width: 20px;
    height: 20px;
    position: absolute;
    top: 4px;
    right: 2px;
    cursor: pointer;
    opacity: 0.5;
}

.settings-popup-close-btn:hover{
    opacity: 1.0;
}

.settings-popup-title {
    /* position: absolute;
    top: 10px;
    left: 30px; */
    margin-top: 3%;
    margin-left: 5%;
    font-size: 20px;
    color: #b0b6c1;
}


/* Made by Jimmy Gillam */
/* Checkmark style starts */
@-moz-keyframes dothabottomcheck {
    0% {
        height: 0;
   }
    100% {
        height: 50px;
   }
}
@-webkit-keyframes dothabottomcheck {
    0% {
        height: 0;
   }
    100% {
        height: 50px;
   }
}
@keyframes dothabottomcheck {
    0% {
        height: 0;
   }
    100% {
        height: 50px;
   }
}
@keyframes dothatopcheck {
    0% {
        height: 0;
   }
    50% {
        height: 0;
   }
    100% {
        height: 120px;
   }
}
@-webkit-keyframes dothatopcheck {
    0% {
        height: 0;
   }
    50% {
        height: 0;
   }
    100% {
        height: 120px;
   }
}
@-moz-keyframes dothatopcheck {
    0% {
        height: 0;
   }
    50% {
        height: 0;
   }
    100% {
        height: 120px;
   }
}
input[type=checkbox] {
    display: none;
}
.check-box {
    height: 100px;
    width: 100px;
    background-color: transparent;
    border: 10px solid #dee6f4;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-transition: border-color ease 0.2s;
    -o-transition: border-color ease 0.2s;
    -webkit-transition: border-color ease 0.2s;
    transition: border-color ease 0.2s;
    cursor: pointer;
}
.check-box::before, .check-box::after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    height: 0;
    width: 20px;
    background-color: #f1966c;
    display: inline-block;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    -webkit-transform-origin: left top;
    transform-origin: left top;
    border-radius: 5px;
    content: ' ';
    -webkit-transition: opacity ease 0.5;
    -moz-transition: opacity ease 0.5;
    transition: opacity ease 0.5;
}
.check-box::before {
    top: 72px;
    left: 41px;
    -moz-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
}
.check-box::after {
    top: 37px;
    left: 5px;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.check-box.checked::after {
    height: 50px;
    -moz-animation: dothabottomcheck 0.2s ease 0s forwards;
    -o-animation: dothabottomcheck 0.2s ease 0s forwards;
    -webkit-animation: dothabottomcheck 0.2s ease 0s forwards;
    animation: dothabottomcheck 0.2s ease 0s forwards;
}
.check-box.checked::before {
    height: 120px;
    -moz-animation: dothatopcheck 0.4s ease 0s forwards;
    -o-animation: dothatopcheck 0.4s ease 0s forwards;
    -webkit-animation: dothatopcheck 0.4s ease 0s forwards;
    animation: dothatopcheck 0.4s ease 0s forwards;
}


/* zoom slider */
input[type="range"] {
	font-size: 1.5rem;
	width: 6.5em;
}

input[type="range"] {
	color: #85c0e0;
	--thumb-height: 0.625em;
	--track-height: 0.125em;
	--track-color: rgba(0, 0, 0, 0.3);
	--brightness-hover: 110%;
	--brightness-down: 80%;
	--clip-edges: 0.125em;
}

input[type="range"] {
	position: relative;
	background: #fff0;
	overflow: hidden;
}

input[type="range"]:active {
	cursor: grabbing;
}

input[type="range"]:disabled {
	filter: grayscale(1);
	opacity: 0.3;
	cursor: not-allowed;
}
input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	transition: all ease 100ms;
	height: var(--thumb-height);
}

input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
	position: relative;
}

input[type="range"]::-webkit-slider-thumb {
	--thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
	--clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
	--clip-bottom: calc(var(--thumb-height) - var(--clip-top));
	--clip-further: calc(100% + 1px);
	--box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
		100vmax currentColor;

	width: var(--thumb-width, var(--thumb-height));
	background: linear-gradient(currentColor 0 0) scroll no-repeat left center /
		50% calc(var(--track-height) + 1px);
	background-color: currentColor;
	box-shadow: var(--box-fill);
	border-radius: var(--thumb-width, var(--thumb-height));

	filter: brightness(100%);
	clip-path: polygon(
		100% -1px,
		var(--clip-edges) -1px,
		0 var(--clip-top),
		-100vmax var(--clip-top),
		-100vmax var(--clip-bottom),
		0 var(--clip-bottom),
		var(--clip-edges) 100%,
		var(--clip-further) var(--clip-further)
	);
}

input[type="range"]:hover::-webkit-slider-thumb {
	filter: brightness(var(--brightness-hover));
	cursor: grab;
}

input[type="range"]:active::-webkit-slider-thumb {
	filter: brightness(var(--brightness-down));
	cursor: grabbing;
}

input[type="range"]::-webkit-slider-runnable-track {
	background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
		100% calc(var(--track-height) + 1px);
}

input[type="range"]:disabled::-webkit-slider-thumb {
	cursor: not-allowed;
}

/* === Firefox specific styles === */
input[type="range"],
input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb {
	appearance: none;
	transition: all ease 100ms;
	height: var(--thumb-height);
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
	background: #fff0;
}

input[type="range"]::-moz-range-thumb {
	background: currentColor;
	border: 0;
	width: var(--thumb-width, var(--thumb-height));
	border-radius: var(--thumb-width, var(--thumb-height));
	cursor: grab;
}

input[type="range"]:active::-moz-range-thumb {
	cursor: grabbing;
}

input[type="range"]::-moz-range-track {
	width: 100%;
	background: var(--track-color);
}

input[type="range"]::-moz-range-progress {
	appearance: none;
	background: currentColor;
	transition-delay: 30ms;
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-progress {
	height: calc(var(--track-height) + 1px);
	border-radius: var(--track-height);
}

input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
	filter: brightness(100%);
}

input[type="range"]:hover::-moz-range-thumb,
input[type="range"]:hover::-moz-range-progress {
	filter: brightness(var(--brightness-hover));
}

input[type="range"]:active::-moz-range-thumb,
input[type="range"]:active::-moz-range-progress {
	filter: brightness(var(--brightness-down));
}

input[type="range"]:disabled::-moz-range-thumb {
	cursor: not-allowed;
}

.custom-dropdown {
    width: 40%;
    padding: 8px;
    font-size: 16px;
    border: 2px solid #deebf4;
    border-radius: 15px;
    background-color: #deeff4;
    cursor: pointer;
    outline: none;
    transition: border-color 0.3s, box-shadow 0.3s;
    text-align: center;
    color: #8d929b;
    
}

/* .custom-dropdown:focus {
    border-color: #007BFF;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
} */

/* Option styling */
.custom-dropdown option {
    padding: 10px;
    background-color: #fff;
    font-size: 16px;
}




